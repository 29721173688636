<template>
  <v-app v-if="pathInfo">
    <v-app-bar app color="white" height="60" dense class="app-bar">
      <v-row class="width-per-100 height-per-100 justify-center">
        <div class="width-per-100 height-per-100 d-flex justify-space-between menu">
          <div class="d-inline-flex justify-start" style="width: 100px;">
            <v-btn plain elevation="0" height="100%" to="/" class="pl-0 logo-btn">
              <img src="../assets/login/logo_lan_bak.png" style="height: 36px;object-fit: contain" />
            </v-btn>
          </div>
          <div class="width-per-100 d-flex justify-end" style="min-width: 350px;">
            <v-hover v-slot="{ hover }" v-for="(link,index) in links.leftList" :key="index">
              <div class="height-per-100 d-inline">
                <v-btn
                  height="100%"
                  light
                  depressed
                  plain
                  @mouseenter="showMenuChildren = true;"
                  :color="currentMenu === link.router ? '#0568FD':''"
                  :ripple="false"
                  class="px-0"
                  :class="index !== 0 ? 'ml-16':''"
                  @click.stop="handleMenuClick(link, false, link);"
                >
                  <div class="menu-title">
                    {{ link.title }}
                    <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                  </div>
                  <div v-if="link.children.length > 0" style="height: 30px;padding-top: 4px;">
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="hover">fas fa-chevron-up</v-icon>
                    <v-icon light left dense class="ml-1 mr-0 font-size-12" v-show="!hover">fas fa-chevron-down</v-icon>
                  </div>
                </v-btn>
                <div v-if="link.children.length > 0" class="d-inline">
                  <v-fade-transition origin="center center">
                    <div class="menu-children width-per-100" v-show="hover && showMenuChildren">
                      <div class="menu-divider">
                        <v-divider></v-divider>
                      </div>
                      <div class="menu-children-content">
                        <div v-for="(linkChildren,indexA) in link.children" :key="indexA" class="height-per-100" :class="indexA !== 0 ? 'flex-grow-1':'flex-grow-1-dot-5'">
                          <v-divider vertical v-if="indexA !== 0"></v-divider>
                          <div :class="link.children.length > 1 ? indexA !== 0 ? 'ml-8':'mr-5':''">
                            <div v-if="linkChildren.title" class="font-weight-bold children-title">
                              <span>{{linkChildren.title}}</span>
                            </div>
                            <v-row class="mt-4 ml-n5">
                              <v-col
                                class="pa-0 mb-5"
                                style="min-height: 51px;max-height: 51px;"
                                :cols="link.children.length > 1 ? indexA !== 0 ? 6 : 4 : 3"
                                v-for="(content,index) in linkChildren.children"
                                :key="index"
                              >
                                <v-hover v-slot="{ hover }">
                                  <v-row class="mr-0 cursor-pointer d-flex align-center ml-3" style="height: 60px;" @click.stop="handleMenuClick(link,true, content);">
                                    <div class="d-inline width-50">
                                      <v-avatar size="50" rounded-2>
                                        <v-img :src="content.icon"></v-img>
                                      </v-avatar>
                                    </div>
                                    <div class="d-inline children-card mt-1">
                                      <v-card color="transparent" flat>
                                        <v-card-title class="pa-0 pt-2" :class="hover ? 'bg-hover':''">
                                          {{content.title}}
                                          <v-img v-if="content.isHot" contain max-width="24" height="10" class="ml-1" src="../../public/img/icons/menu/hot.png"></v-img>
                                        </v-card-title>
                                        <v-card-text class="pa-0 pb-2 mt-1" :class="hover ? 'bg-hover':''" style="line-height: 1.4">
                                          <span style="opacity: 0.6">{{content.explain}}</span>
                                        </v-card-text>
                                      </v-card>
                                    </div>
                                  </v-row>
                                </v-hover>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-fade-transition>
                </div>
              </div>
            </v-hover>
          </div>
          <!--<div class="width-per-100 d-inline-flex justify-end">-->
          <!--  <v-hover-->
          <!--      v-slot="{ hover }"-->
          <!--      v-for="(link,index) in links.rightList"-->
          <!--      :key="index">-->
          <!--    <div class="height-per-100 d-inline">-->
          <!--      <v-btn-->
          <!--          height="100%"-->
          <!--          light depressed plain-->
          <!--          :color="currentMenu === link.router ? '#0568FD':''"-->
          <!--          :ripple="false"-->
          <!--          class="px-0"-->
          <!--          :class="index !== 0 ? 'ml-16':''"-->
          <!--          @click.stop="handleMenuClick(link, false, link)"-->
          <!--      >-->
          <!--        <div class="menu-title">{{ link.title }}-->
          <!--          <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>-->
          <!--        </div>-->
          <!--        <span v-if="link.children.length > 0">-->
          <!--          <v-icon light left dense class="ml-1 font-size-12" v-show="hover">fas fa-chevron-up</v-icon>-->
          <!--          <v-icon light left dense class="ml-1 font-size-12" v-show="!hover">fas fa-chevron-down</v-icon>-->
          <!--        </span>-->
          <!--      </v-btn>-->
          <!--    </div>-->
          <!--  </v-hover>-->
          <!--</div>-->
        </div>
      </v-row>
    </v-app-bar>
    <v-main style="position: relative;z-index: 6;">
      <router-view />
    </v-main>

    <!-- 底部 -->
    <OfficialWebsiteFooter />
    <!-- 右侧悬浮 -->
    <RightSuspend />

  </v-app>
  <router-view v-else />
</template>

<script>
let domains = process.env.VUE_APP_NODE_ENV === 'production' ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN
domains = domains.split(',')
console.log(domains)
console.log(process.env.VUE_APP_NODE_ENV)
import { mapState } from 'vuex'
import { browserVersion } from '@/utils/utils'
// let domains = process.env.NODE_ENV === "production" ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN;
// domains = domains.split(",");
export default {
  name: 'App',
  components: { 
    OfficialWebsiteFooter: () => import('@/components/common/officialWebsiteFooter.vue'),
    RightSuspend: () => import('@/components/common/rightSuspend.vue'),
  },
  data: () => ({
    showHeaderMenu: ['', 'serviceProducts', 'solutions', 'numberProducts', 'numberSolutions', 'CooperationCase', 'About'],
    pathInfo: true,
    currentMenu: '/',
    links: {
      leftList: [
        {
          title: '首页',
          router: '/',
          children: []
        },
        {
          title: '产品',
          router: '/numberProducts',
          children: [
            {
              title: '产品',
              children: [
                {
                  title: 'FT引擎',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-1-FT.png',
                  router: '/numberProducts/FT',
                  explain: '自主研发的三维引擎'
                },
                {
                  title: 'SaaS',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-2.png',
                  router: '/numberProducts/SaasUGC',
                  explain: '元宇宙UGC工具'
                },
                {
                  title: '硬件产品',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-3.png',
                  router: '/numberProducts/Hardware',
                  explain: '丰富的硬件体系适配各种展示场景'
                },
                {
                  title: '数字人',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-4.png',
                  router: '/DigitalMan',
                  isNewWindow: true,
                  explain: 'AI与生产力结合的新物种'
                },
                {
                  title: 'AIGC',
                  icon: 'https://h5.ophyer.cn/official_website/icon/aigc-menu-icon-4.png',
                  router: '/aigc',
                  isNewWindow: true,
                  explain: '探索AI边界，提升AI技术水平'
                },
                /* {
                  title: '数字资产',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-5.png',
                  router: '/numberProducts/DigitalAssets',
                  explain: '更高效的数字资产评估和管理'
                } */
              ]
            }
          ]
        },
        {
          title: '解决方案',
          router: '/numberSolutions',
          children: [
            {
              title: '解决方案',
              children: [
                {
                  title: '娱乐',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-6.png',
                  router: '/numberSolutions/Entertainment',
                  explain: 'VR直播，在自己的场景中实时互动'
                },
                {
                  title: '文博和旅游',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-7.png',
                  router: '/numberSolutions/CulturalTourism',
                  explain: '文博艺术3D/VR数字化互动展示专家'
                },
                {
                  title: '新零售',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-8.png',
                  router: '/numberSolutions/NewRetail',
                  explain: 'VR云店助力店铺销售转化提升'
                },
                {
                  title: '房地产',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-9.png',
                  router: '/numberSolutions/RealEstate',
                  explain: '提供沉浸式的线上看房体验'
                },
                {
                  title: '工业制造',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-10.png',
                  router: '/numberSolutions/IndustrialManufacturing',
                  explain: '数字孪生助力工厂数字化转型'
                },
                {
                  title: '职业培训',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-11.png',
                  router: '/numberSolutions/VocationalTraining',
                  explain: '推动现代信息技术融入实验教学'
                },
                {
                  title: '元宇宙解决方案',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-12.png',
                  router: '/numberSolutions/MetaverseSolution',
                  explain: '一站式元宇宙解决方案'
                },
                {
                  title: '媒体运营',
                  icon: 'https://h5.ophyer.cn/official_website/icon/num-menu-icon-13.png',
                  router: '/numberSolutions/MediaOperation',
                  explain: '元宇宙时代的品牌运营专家'
                }
              ]
            }
          ]
        },
        {
          title: '飞天元宇宙',
          router: '',
          href: 'https://meta.ophyer.com/',
          children: []
        },
        // {
        //   title: '数字化教育',
        //   router: '/education',
        //   newPage: true,
        //   children: []
        // },
        {
          title: '投资者关系',
          router: '',
          href: 'https://www.flowingcloud.com',
          children: []
        },
        {
          title: '关于我们',
          router: '/About',
          children: []
        }
      ],
      rightList: [
        {
          title: '合作案例',
          router: '/CooperationCase',
          children: []
        },
        {
          title: '关于我们',
          router: '/About',
          children: []
        }
      ]
    },
    fab: true,
    showMenuChildren: false,
    isIE: browserVersion()
  }),
  computed: {
    ...mapState(['officialWebsite'])
  },
  watch: {
    $route(to) {
      const menuInfo = to.path.split('/')
      this.currentMenu = '/' + menuInfo[1]
      this.pathInfo = this.showHeaderMenu.indexOf(window.location.pathname.split('/')[1]) !== -1
    }
  },
  mounted() {
    this.pathInfo = this.showHeaderMenu.indexOf(window.location.pathname.split('/')[1]) !== -1
  },
  methods: {
    handleMenuClick: function (menu, clickChildren, content) {
      if (menu.children.length !== 0 && !clickChildren) return
      if (menu.href) {
        window.open(menu.href)
        return
      }
      if (content) {
        if (content.outerChain) {
          window.open(content.outerChain)
        } else if (content.router) {
          if (content.isNewWindow) {
            window.open(window.location.protocol + '//' + window.location.host + content.router)
          } else {
            this.$router.push(content.router)
          }
        }
      }
      this.showMenuChildren = false
    },
 
  }
}
</script>
<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #969799;
}
::v-deep .app-bar {
  line-height: 1;
  box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1) !important;
  z-index: 1000 !important;
  hr {
    border-color: #333333;
    opacity: 0.2;
  }
  .v-toolbar__content {
    max-width: 1200px;
    margin: 0 auto;
    .logo-btn {
      margin-left: -20px;
      padding: 0;
      .v-btn__content {
        opacity: 1 !important;
        height: 100%;
      }
    }
    .menu {
      margin: 0 auto;
      max-width: 1200px;
      .v-btn:not(.v-btn--round).v-size--default {
        min-width: unset;
      }
    }
    .menu-hover {
      border-bottom: 2px solid #333333;
    }
    .menu-title {
      font-size: 16px;
      height: 30px;
      position: relative;
      .menu-btn-active {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        height: 3px;
        width: 30px;
        background-color: #0568fd;
        //font-family: $bold-font-family;
      }
    }
    .menu-divider {
      width: 100%;
      position: fixed;
      top: 61px;
      left: 0;
      display: flex;
      justify-content: center;
      hr {
        max-width: 1200px;
        margin: 0 auto;
      }
    }
    .menu-children {
      //height: 320px;
      width: 100%;
      position: fixed;
      top: 60px;
      left: 0;
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      padding: 40px 0 30px 0;
      box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1);
      .menu-children-content {
        min-width: 1024px;
        max-width: 1200px;
        width: 100%;
        display: flex;
        height: 100%;
        //align-items: center;
        justify-content: center;
        position: relative;
        //font-family: SourceHanSansCN-Normal,sans-serif;
        hr {
          position: absolute;
          top: 0;
        }
        .children-title {
          font-size: 18px;
          height: 18px;
          //font-family: $normal-font-family;
        }
        .children-card {
          width: calc(100% - 60px);
          margin-left: 5px;
          .v-card__title {
            font-size: 16px;
          }
          .v-card__text {
            font-size: 10px;
          }
        }
      }
    }
  }
}
</style>
